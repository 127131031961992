@import '../../../scss/theme-bootstrap';

body.device-mobile {
  #cart_wrapper {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    z-index: 100;
    visibility: hidden;
  }
  #cart_wrapper.hover {
    visibility: visible;
  }
  .cart-confirm-wrapper {
    min-height: 200px;
    width: 100%;
    text-align: center;
    background-color: $color-white;
    font-size: 13px;
  }
  .cart-confirm-wrapper a,
  .cart-confirm-wrapper a:hover {
    text-decoration: none;
  }
  .cart-confirm-wrapper {
    .cart-product-container {
      a {
        @if $cr19 == true {
          @include text-body-text--no-xl;
          @include text-link;
          @include text-link--style-2;
        }
      }
    }
  }
  .cart-overlay-products {
    border: solid 1px #ccc;
    border-width: 1px 0px;
    padding: 10px 0 20px;
  }
  .cart-confirm-wrapper h2 {
    @include swap_direction(margin, 0px 0px 10px 0px);
    font-size: 13px;
    @if $cr19 == true {
      @include text-title--med;
      padding: 12px;
    }
    b {
      color: $color-cl-green-2;
      @if $cr19 == true {
        @include text-title--med;
        color: $cr19-text;
      }
    }
  }
  .cart-confirm-wrapper .cart-overlay-products h3 {
    font-size: 13px;
    padding: 10px 40px 0px;
  }
  .cart-confirm-wrapper h4 {
    font-size: 11px;
    @if $cr19 == true {
      @include text-body-text--no-xl;
    }
  }
  .cart-confirm-wrapper .buttons {
    padding: 10px 20px 20px;
    .button {
      padding: 0px;
      @if $cr19 == true {
        @include elc-button;
        @include elc-button--dark;
      }
      &.btn-continue {
        background-color: $color-btn-hover;
        @if $cr19 == true {
          @include elc-button--light;
        }
      }
    }
  }
  .gnav-cart-dropdown .gnav-item-content.empty,
  .gnav-cart-dropdown.hover .gnav-item-content.empty {
    display: none;
  }
}
